import api from '@/utils/request'
var url = ''
switch (process.env.NODE_ENV) {
  case 'dev':
    url = 'http://server-operate-test.jixedu.cn'
    break;
  case 'test':
    url = 'http://server-operate-test.jixedu.cn'
    break;
  case 'production':
    url = 'https://operate-server.jixedu.cn'
    break;
  default:

}

export function DownloadType(data) {
  return api({
    method: 'POST',
    url: url + '/extension/upLoadEscalation',
    data,
  })
}